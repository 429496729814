import { Form, Formik } from 'formik';
import { bool, func } from 'prop-types';
import React, { useCallback } from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { Button } from '../../button';
import { Input } from '../../form';
import './login.scss';

const bem = new BEMHelper({
  name: 'auth-login',
});

const REGISTRATION_ENABLED = process.env.GATSBY_REGISTRATION_ENABLED === 'true';

export const Login = ({
  withoutDetails, onLogin, onResetPassword, onRequestAccess,
}) => {
  const { t } = useTranslation();

  const onSubmit = useCallback(async (credentials, { setFieldError, setSubmitting }) => {
    try {
      await onLogin(credentials);
    } catch (error) {
      setFieldError('general', error?.description || t('An error occurred.'));
      setSubmitting(false);
    }
  }, [onLogin]);

  return (
    <Formik onSubmit={onSubmit} initialValues={{ email: '', password: '' }}>
      {({
        values,
        errors,
        touched,
        handleBlur,
      }) => (
        <Form {...bem()}>
          {!withoutDetails && (
            <div {...bem('details')}>
              <h2 {...bem('headline')}>{t('Sign in')}</h2>
              <p {...bem('description')}>
                {t('You have a personal account? Use your credentials here to sign in.')}
              </p>
              {errors.general && (<p {...bem('error')} role="alert">{errors.general}</p>)}
            </div>
          )}

          <div {...bem('form')}>
            <Input
              label={t('E-Mail')}
              type="email"
              name="email"
              id="email"
              value={values.email}
              error={errors.email && touched.email && errors.email}
              onBlur={handleBlur}
              required
            />

            <Input
              label={t('Password')}
              type="password"
              name="password"
              id="password"
              value={values.password}
              error={errors.password && touched.password && errors.password}
              onBlur={handleBlur}
              required
            />

            <Button
              {...bem('submit')}
              type="submit"
              title={t('Login with your credentials')}
            >
              {t('Sign in')}
            </Button>

            {REGISTRATION_ENABLED && onRequestAccess && (
              <button
                {...bem('request')}
                type="button"
                onClick={onRequestAccess}
                title={t('Request access')}
              >
                {t('No login? Request access')}
              </button>
            )}

            {onResetPassword && (
              <p {...bem('reset')}>
                {t('Can\'t remember your password?')}
                {' '}
                <button
                  {...bem('reset__link')}
                  type="button"
                  title={t('Reset your password')}
                  onClick={onResetPassword}
                >
                  {t('Request a new one')}
                </button>
                .
              </p>
            )}

            {!REGISTRATION_ENABLED && (
              <div {...bem('registration-inactive')}><br /><em>Registration is only possible on our B2B
                platform.<br/>Please <a href={'https://shop.medskin-suwelack.com/account/register'}>follow this
                  link</a> to register.</em></div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

Login.propTypes = {
  withoutDetails: bool,
  onLogin: func.isRequired,
  onResetPassword: func,
  onRequestAccess: func,
};

Login.defaultProps = {
  withoutDetails: false,
  onResetPassword: null,
  onRequestAccess: null,
};
